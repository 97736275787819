import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Color } from '../../../_shared/enums/color';
import { richText } from '../../../modules/rich-text';
import Background from '../../templates/background/background';
import Breakpoint from '../../templates/breakpoint/breakpoint';
import Button, { ButtonThemeColor, ButtonThemeType } from '../../templates/button/button';
import HeadlineCopytext from '../../templates/headline-copytext/headline-copytext';

import TrialMonthStyles from './trial-month.module.scss';

const TrialMonthMirror = () => {
    const intl = useIntl();

    return (
        <div className={TrialMonthStyles.trialMonth}>
            <div className={TrialMonthStyles.trialMonthLeft}>
                <div className={TrialMonthStyles.trialMonthLeftTop}>
                    <HeadlineCopytext
                        color={Color.White}
                        alignment="left"
                        headline={[
                            {
                                style: 'h5',
                                text: intl.formatMessage({ id: 'pages.mirror.sections.03.headlines.0' }),
                            },
                            {
                                style: 'h5',
                                text: intl.formatMessage({ id: 'pages.mirror.sections.03.headlines.1' }),
                            },
                            {
                                style: 'h5',
                                text: richText('pages.mirror.sections.03.headlines.2'),
                            },
                        ]}
                    />

                    <p className="bold color-white">{richText('pages.mirror.sections.03.copytext')}</p>

                    <ul>
                        <li>
                            <p>{richText('pages.mirror.sections.03.list.0')}</p>
                        </li>
                        <li>
                            <p>{richText('pages.mirror.sections.03.list.1')}</p>
                        </li>
                        <li>
                            <p>{richText('pages.mirror.sections.03.list.2')}</p>
                        </li>
                        <li>
                            <p>{richText('pages.mirror.sections.03.list.3')}</p>
                        </li>
                        <li>
                            <p>{richText('pages.mirror.sections.03.list.4')}</p>
                        </li>
                        <li>
                            <p>{richText('pages.mirror.sections.03.list.5')}</p>
                        </li>
                    </ul>
                    <Button
                        type="link"
                        href="https://shop-horizonfitness.eu/collections/horizon@"
                        target="shop-mirror"
                        theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Pink }}
                    >
                        <FormattedMessage id="pages.mirror.sections.03.button" />
                    </Button>
                </div>

                <div className={TrialMonthStyles.trialMonthLeftBottom}>
                    {/*<h6 className="color-pink">*/}
                    {/*    <FormattedMessage id="pages.mirror.sections.03.teaser.headline" />*/}
                    {/*</h6>*/}
                    {/*<p className="bold color-pink">{richText('pages.mirror.sections.03.teaser.copytext.0')}</p>*/}
                    {/*<p className="bold color-pink">{richText('pages.mirror.sections.03.teaser.copytext.1')}</p>*/}
                </div>
            </div>

            <div>
                <Breakpoint small={true} medium={true}>
                    <Background
                        height="56.25%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/mirror/03/landscape.jpg"
                        videoPath="_assets/pages/mirror/03/landscape.mp4"
                    />
                </Breakpoint>

                <Breakpoint large={true}>
                    <Background
                        height="160%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/mirror/03/portrait-large.jpg"
                        videoPath="_assets/pages/mirror/03/portrait-large.mp4"
                    />
                </Breakpoint>

                <Breakpoint extraLarge={true} ultraLarge={true}>
                    <Background
                        height="126%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/mirror/03/portrait-extralarge.jpg"
                        videoPath="_assets/pages/mirror/03/portrait-extralarge.mp4"
                    />
                </Breakpoint>
            </div>
        </div>
    );
};

export default TrialMonthMirror;
