import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { Slide } from 'react-awesome-reveal';
// import { InView } from 'react-intersection-observer';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Color } from '../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../components/common/page-layout/page-layout';
// import Newsletter from '../components/content/newsletter/newsletter';
// import Stockist from '../components/content/stockist/stockist';
import StreamTeam from '../components/content/stream-team/stream-team';
import TrialMonthMirror from '../components/content/trial-month/trial-month-mirror';
import Background from '../components/templates/background/background';
import Breakpoint from '../components/templates/breakpoint/breakpoint';
import Button, { ButtonThemeColor, ButtonThemeType } from '../components/templates/button/button';
import HeadlineCopytext from '../components/templates/headline-copytext/headline-copytext';
import ResponsiveImage from '../components/templates/responsive-image/responsive-image';
import SectionIntended from '../components/templates/section-intended/section-intended';
import YouTubeVideoOverlayContext from '../context/youtube-video-overlay.context';

import MirrorStyles from './_scss/mirror.module.scss';
import SharedStyles from './_scss/shared.module.scss';
import { richText } from '../modules/rich-text';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const MirrorPage = () => {
    const intl = useIntl();
    const { open: openYouTubeVideoOverlay } = React.useContext(YouTubeVideoOverlayContext);

    const videos = {
        section01: { small: 'fQZqq6yeShs', large: 'VuDJfAyMOM0' },
        section10Live: { large: 'gi1jPdcmGM8' },
        section10OnDemand: { large: 'yhG2tqOmtOI' },
    };

    const showVideo = (event: any, videoName: string) => {
        event.preventDefault();
        if (typeof window !== `undefined` && videos[videoName]) {
            const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
            const youTubeId =
                deviceWidth < 768 && videos[videoName].small ? videos[videoName].small : videos[videoName].large;
            openYouTubeVideoOverlay(youTubeId, deviceWidth < 768 && videos[videoName].small ? '9:16' : '16:9');
        }
    };

    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.mirror.title' })}
            description={intl.formatMessage({ id: 'pages.mirror.description' })}
            topPadding={false}
            pageBackground={PageLayoutBackground.Black}
        >
            {/*01 START*/}
            <div className={SharedStyles.mainVideo}>
                <Breakpoint small={true}>
                    <Background
                        height="177%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/mirror/01/background-portrait.jpg"
                        videoPath="_assets/pages/mirror/01/portrait.mp4"
                    />
                </Breakpoint>

                <Breakpoint medium={true} large={true} extraLarge={true} ultraLarge={true}>
                    <Background
                        height="56.25%"
                        backgroundColor={Color.Black}
                        imagePath="_assets/pages/mirror/01/background-landscape.jpg"
                        videoPath="_assets/pages/mirror/01/landscape.mp4"
                    />
                </Breakpoint>

                <div className={SharedStyles.mainVideoContent}>
                    <HeadlineCopytext
                        color={Color.White}
                        alignment="center"
                        headline={[
                            {
                                style: 'h1',
                                text: 'YOUR',
                            },
                            {
                                style: 'h2',
                                text: 'COM-FIT',
                            },
                            {
                                style: 'h1',
                                text: 'ZONE',
                            },
                        ]}
                        copytext={[
                            {
                                text:
                                    'Der @Mirror beamt dich direkt vom Wohnzimmer ins Workout mit deinen Buddys. Du machst deinen Lieblingssport an deinem Lieblingsort, immer mittendrin und trotzdem mit perfektem Überblick.',
                            },
                        ]}
                    >
                        <Button
                            type="button"
                            onClick={(e: any) => showVideo(e, 'section01')}
                            theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Transparent }}
                        >
                            Video ansehen
                        </Button>
                    </HeadlineCopytext>
                </div>
            </div>
            {/*01 END*/}

            <SectionIntended noPadding={true}>
                {/*02 START*/}
                <div className={[SharedStyles.mainHomechamps, SharedStyles.mainHomechampsNaomi].join(' ')}>
                    <div className={SharedStyles.mainHomechampsContent}>
                        <HeadlineCopytext
                            color={Color.Yellow}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: 'FÜR',
                                },
                                {
                                    style: 'h4',
                                    text: 'HOME CHAMPS,',
                                },
                                {
                                    style: 'h3',
                                    text: 'DIE IHR TRAINING',
                                },
                                {
                                    style: 'h4',
                                    text: 'PERSÖNLICH NEHMEN',
                                },
                            ]}
                        />
                        <p className={[SharedStyles.mainHomechampsContentCopytext, 'color-yellow'].join(' ')}>
                            Mit dem Horizon@Mirror und unserem Horizon@Abo{' '}
                            <sup>
                                <a href="#footnote-(1)">(1)</a>
                            </sup>{' '}
                            streamst du Workout Classes und Trainingspartner direkt auf das große Hidden Display. Dank
                            exzellenter Spiegelwirkung der Oberfläche korrigierst du gleichzeitig deine eigene Haltung –
                            und erhältst in Trainingspausen ein stylisches Wohnaccessoire.
                        </p>
                    </div>

                    <div className={SharedStyles.mainHomechampsImage}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/mirror/02/background.png',
                                    media: '',
                                },
                            ]}
                            altText=""
                        />
                    </div>
                </div>
                {/*02 END*/}

                {/*03 START*/}
                <TrialMonthMirror />
                {/*03 END*/}

                {/*04 START*/}
                <div className={SharedStyles.mainHardware}>
                    <ResponsiveImage
                        images={[
                            {
                                path: '_assets/pages/mirror/04/background-small.jpg',
                                media: '(max-width: 767px)',
                            },
                            {
                                path: '_assets/pages/mirror/04/background-medium.jpg',
                                media: '(min-width: 768px) and (max-width: 1024px)',
                            },

                            {
                                path: '_assets/pages/mirror/04/background-large.jpg',
                                media: '(min-width: 1025px)',
                            },
                        ]}
                        altText=""
                    />

                    {/*HEAD START*/}
                    <div className={SharedStyles.mainHardwareHead}>
                        <HeadlineCopytext
                            color={Color.Aqua}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: 'DIE',
                                },
                                {
                                    style: 'h4',
                                    text: 'HARDWARE',
                                },
                            ]}
                        />

                        <Button
                            href="/downloads/Produktdatenblatt@Mirror.pdf"
                            target="ProduktdatenblattMirror"
                            theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Blue }}
                            className={SharedStyles.mainHardwareButton}
                        >
                            PRODUKTDATENBLATT DOWNLOADEN
                        </Button>
                    </div>

                    {/*HEAD END*/}

                    {/*LIST START*/}
                    <div className={SharedStyles.mainHardwareList}>
                        <div>
                            <h6>40“ Hidden Display </h6>
                            <p>
                                Das Auge trainiert mit! Im 40“ Full-HD-Display lassen sich auch kleinste Details der
                                Übungen erkennen – und direkt umsetzen: Dank hervorragender Spiegelwirkung kannst du
                                deine Haltung nämlich konstant sehen und verbessern.
                            </p>
                        </div>

                        <div>
                            <h6>Standby-Funktionen</h6>
                            <p>
                                Flexen oder relaxen? Im Standby-Modus ist der @Mirror ein stylisches Wohnaccessoire: als
                                Spiegel mit Wetter- und Zeitanzeige oder als großer digitaler Bilderrahmen mit
                                Slideshow-Funktion.
                            </p>
                        </div>

                        <div>
                            <h6>Steuerung per App</h6>
                            <p>
                                Dank der Steuerung per App kannst du während der Bedienung des @Mirrors einfach sitzen
                                bleiben. Oder liegen. Oder planken, im Kopfstand, in der Brücke … du verstehst. Und das
                                Abwischen der Fingerabdrücke sparst du dir auch.
                            </p>
                        </div>

                        <div>
                            <h6>Flexibler Standfuß</h6>
                            <p>
                                Solide Beinarbeit: Mit dem optional erhältlichen Ständer kannst du deinen @Mirror dank
                                der leichtgängigen Rollen ganz einfach beinahe überall aufstellen und den optimalen
                                Betrachtungswinkel einstellen.
                            </p>
                        </div>
                    </div>
                    {/*LIST END*/}
                </div>
                {/*04 END*/}

                {/*05 START*/}
                <div className={MirrorStyles.section05}>
                    <div className={MirrorStyles.section05Highlight}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/mirror/05/highlights-1-portrait.jpg',
                                    media: '(max-width: 767px)',
                                },
                                {
                                    path: '_assets/pages/mirror/05/highlights-1-landscape.jpg',
                                    media: '(min-width: 768px)',
                                },
                            ]}
                            altText=""
                        />
                        <h6 className="color-white bold">
                            Integrierte Frontkamera mit <span className="no-wrap">5 Megapixeln</span>
                        </h6>
                    </div>

                    <div className={MirrorStyles.section05Grid}>
                        <div className={MirrorStyles.section05Highlight}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/mirror/05/highlights-2.jpg', media: '' }]}
                                altText=""
                            />
                            <h6 className="color-white bold">Integrierte Boxen</h6>
                        </div>

                        <div className={MirrorStyles.section05Highlight}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/mirror/05/highlights-3.jpg', media: '' }]}
                                altText=""
                            />

                            <h6 className="color-white bold">Inklusive Wandhalterung</h6>
                        </div>

                        <div className={MirrorStyles.section05Headline}>
                            <h3 className="color-blue">WEITERE HIGHLIGHTS</h3>
                            <h3 className="color-blue">
                                WEITERE
                                <br />
                                HIGHLIGHTS
                            </h3>
                        </div>
                    </div>
                </div>
                {/*05 END*/}

                {/*06 START*/}
                <div className={[SharedStyles.mainAward, 'gradient-background-animated'].join(' ')}>
                    <div>
                        <Slide direction="right" triggerOnce={false} delay={0}>
                            <HeadlineCopytext
                                color={Color.White}
                                alignment="left"
                                headline={[
                                    {
                                        style: 'h3',
                                        text: 'AUSGEZEICHNETES',
                                    },
                                    {
                                        style: 'h4',
                                        text: 'DESIGN',
                                    },
                                ]}
                                copytext={[
                                    {
                                        text:
                                            'Egal, was du gerade darin siehst: Der @Mirror sieht überall gut aus. Mit großzügigen 129,6 x 58,4 cm passt er als stylisches Wohnaccessoire in praktisch jede Umgebung, an der Wand genauso wie im Stand. Und wurde auch dafür mit dem Good Design Award prämiert.',
                                    },
                                ]}
                            />
                        </Slide>
                    </div>

                    <div>
                        <div className={SharedStyles.mainAwardImage}>
                            <ResponsiveImage
                                images={[{ path: '_assets/pages/cycle/06/good-design-award-logo.png', media: '' }]}
                                altText=""
                            />
                        </div>
                    </div>
                </div>
                {/*06 END*/}
            </SectionIntended>
            {/*07 START*/}
            <div className={MirrorStyles.section07}>
                <ResponsiveImage
                    images={[
                        {
                            path: '_assets/pages/mirror/07/background-portrait.jpg',
                            media: '(max-width: 767px)',
                        },
                        {
                            path: '_assets/pages/mirror/07/background-landscape.jpg',
                            media: '(min-width: 768px)',
                        },
                    ]}
                    altText=""
                />

                <div className={MirrorStyles.section07Content}>
                    <Slide direction="up" triggerOnce={true} delay={0}>
                        <HeadlineCopytext
                            color={Color.White}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h1',
                                    text: 'EASY',
                                },
                                {
                                    style: 'h2',
                                    text: 'DOES',
                                },
                                {
                                    style: 'h1',
                                    text: 'HIIT',
                                },
                            ]}
                            copytext={[
                                {
                                    text:
                                        'Home Champs trainieren hart – wann und wie es ihnen gefällt. Denn mit Horizon@ findest du dein individuelles Lieblings-Workout in unzähligen CYBEROBICS LIVE und On-Demand-Classes, jederzeit für jedes Level, solo oder gemeinsam.',
                                },
                            ]}
                        />
                    </Slide>
                </div>
            </div>
            {/*07 END*/}
            <SectionIntended noPadding={true}>
                {/*08 START*/}
                <div className={[SharedStyles.mainTraining, MirrorStyles.section08].join(' ')}>
                    <div className={SharedStyles.mainTrainingHead}>
                        <HeadlineCopytext
                            alignment="center"
                            headline={[
                                {
                                    style: 'h3',
                                    text: 'DAS TRAINING',
                                    color: Color.Aqua,
                                },
                            ]}
                            copytext={[
                                {
                                    text:
                                        'In den LIVE Classes von CYBEROBICS, mit multidimensionalen Licht- und Soundshows, sorgen die Profi-Trainer dafür, dass du dich nach jedem Workout wie ein echter Champ fühlst.',
                                    color: Color.White,
                                    style: 'bold',
                                },
                            ]}
                        />
                    </div>

                    <div className={SharedStyles.mainTrainingList}>
                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconStrength].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/mirror/08/strength.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Strength</p>
                            <p>
                                In den LIVE Classes STRONG, PUMP und SIXPACK pushen unsere Trainer dich zu spür- und
                                sichtbar mehr Power im gesamten Körper.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconCardio].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/mirror/08/cardio.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Cardio</p>
                            <p>
                                Die schweißtreibenden Cardio LIVE Classes BURN, BOX, SWEAT & DANCE bringen dich an deine
                                Grenzen – und wenn du willst, auch darüber hinaus.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconCycle].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/mirror/08/cycling.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Cycling</p>
                            <p>
                                Trainiere in wöchentlich über 50 verschiedenen Cycling LIVE Classes für alle Ansprüche:
                                Kalorien verbrennen und Körper straffen, Herz-Kreislaufsystem auf Touren bringen,
                                komplett auspowern oder einfach mal abschalten.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconToning].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/mirror/08/toning.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Toning</p>
                            <p>
                                Bei TONE, STEP, HEALTH und BARRE straffst und formst du deinen Körper – oder ausgewählte
                                Körperpartien – mit hocheffektiven Bodyweight-Übungen.
                            </p>
                        </div>

                        <div className={SharedStyles.mainTrainingListItem}>
                            <div
                                className={[SharedStyles.mainTrainingIcon, SharedStyles.mainTrainingIconBodyMind].join(
                                    ' ',
                                )}
                            >
                                <ResponsiveImage
                                    images={[{ path: '_assets/pages/mirror/08/body-mind.png', media: '' }]}
                                    altText=""
                                />
                            </div>
                            <p className={['bold', SharedStyles.mainTrainingListItemHeadline].join(' ')}>Body & Mind</p>
                            <p>
                                FLOW, PILATES und RELAX maximieren deine innere Stärke – auch ganz wörtlich dank
                                kräftiger Tiefenmuskulatur und besserer Koordination. Funktionales Rückentraining gibt’s
                                bei der LIVE Class BACK.
                            </p>
                        </div>
                    </div>
                </div>
                {/*08 END*/}
                {/*09 START*/}
                <StreamTeam type="mirror" />
                {/*09 END*/}
                {/*10 START*/}
                <div className={SharedStyles.mainClasses}>
                    <div className={SharedStyles.mainClassesImageContainer}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/mirror/10/live-landscape.jpg',
                                    media: '(max-width: 767px), (min-width: 1025px)',
                                },
                                {
                                    path: '_assets/pages/mirror/10/live-portrait.jpg',
                                    media: '(min-width: 768px) and (max-width: 1024px)',
                                },
                            ]}
                            altText=""
                        />
                        <div className={SharedStyles.mainClassesImageContainerContent}>
                            <Slide direction="left" triggerOnce={false} delay={0}>
                                <HeadlineCopytext
                                    color={Color.White}
                                    alignment="center"
                                    headline={[
                                        {
                                            style: 'h5',
                                            text: 'LIVE',
                                        },
                                        {
                                            style: 'h5',
                                            text: 'CLASSES',
                                        },
                                    ]}
                                />
                            </Slide>
                            <Button
                                className={SharedStyles.mainClassesImageContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10Live')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Blue }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>

                    <div className={SharedStyles.mainClassesTextContainer}>
                        <div className={SharedStyles.mainClassesTextContainerContent}>
                            <p className="bold">Live</p>
                            <p>
                                In wöchentlich über 150 neuen CYBEROBICS LIVE Classes aus den Kategorien Cardio,
                                Strength, Toning, Body & Mind und Cycling pushen dich spezialisierte Trainer an dein
                                individuelles Ziel. Danach kannst du alle LIVE Classes noch eine Woche lang in der LIVE
                                Collection abrufen.
                            </p>
                            <Button
                                className={SharedStyles.mainClassesTextContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10Live')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Blue }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>

                    <div className={SharedStyles.mainClassesImageContainer}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/cycle/10/on-demand-landscape.jpg',
                                    media: '(max-width: 767px), (min-width: 1025px)',
                                },
                                {
                                    path: '_assets/pages/cycle/10/on-demand-portrait.jpg',
                                    media: '(min-width: 768px) and (max-width: 1024px)',
                                },
                            ]}
                            altText=""
                        />
                        <div className={SharedStyles.mainClassesImageContainerContent}>
                            <Slide direction="right" triggerOnce={false} delay={0}>
                                <HeadlineCopytext
                                    color={Color.White}
                                    alignment="center"
                                    headline={[
                                        {
                                            style: 'h5',
                                            text: 'On-',
                                        },
                                        {
                                            style: 'h5',
                                            text: 'Demand',
                                        },
                                        {
                                            style: 'h5',
                                            text: 'Workouts',
                                        },
                                    ]}
                                />
                            </Slide>
                            <Button
                                className={SharedStyles.mainClassesImageContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10OnDemand')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Blue }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>

                    <div className={SharedStyles.mainClassesTextContainer}>
                        <div className={SharedStyles.mainClassesTextContainerContent}>
                            <p className="bold">On demand</p>
                            <p>
                                Rund um die Uhr stehen dir unzählige professionelle On-Demand-Workouts für jedes Ziel
                                und jedes Level zum Streaming bereit.
                            </p>
                            <Button
                                className={SharedStyles.mainClassesTextContainerContentButton}
                                type="button"
                                onClick={(e: any) => showVideo(e, 'section10OnDemand')}
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Blue }}
                            >
                                JETZT ANSEHEN
                            </Button>
                        </div>
                    </div>
                </div>
                {/*10 END*/}
                {/*11 START*/}
                <div className={[MirrorStyles.section11].join(' ')}>
                    <div className={MirrorStyles.section11Content}>
                        <h3 className="color-rose">DIE APP</h3>
                        <p className="color-white">
                            Mit der Horizon@Mirror App wird dein Smartphone zum Knotenpunkt deines gesamten Trainings.
                            Über sie verabredest du 1:1 Workouts, reservierst Termine oder teilst deine
                            Trainingsergebnisse.
                            <sup>
                                <a href="#footnote-(2)">(2)</a>
                            </sup>
                        </p>
                        <p>&nbsp;</p>
                        <p className="color-white">
                            Und weil du auch den @Mirror komplett per App steuerst, kannst du ihn ohne Unterbrechung
                            während des Trainings bedienen – egal, ob du gerade am Planken, im Lotussitz oder mitten im
                            zwölften Burpee bist. Und ganz nebenbei hinterlässt du auch keine nervigen Fingerabdrücke
                            auf dem Display.
                        </p>
                        {/*<div className={MirrorStyles.section11AppLinks}>*/}
                        {/*    <a*/}
                        {/*        href="https://example.com"*/}
                        {/*        aria-label="Laden im App Store"*/}
                        {/*        target="appstore"*/}
                        {/*        rel="noopener noreferrer"*/}
                        {/*    />*/}
                        {/*    <a*/}
                        {/*        href="https://example.com"*/}
                        {/*        aria-label="Jetzt bei Google Play"*/}
                        {/*        target="playstore"*/}
                        {/*        rel="noopener noreferrer"*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                    <div className={MirrorStyles.section11Image}>
                        <ResponsiveImage
                            images={[
                                {
                                    path: '_assets/pages/mirror/11/app.png',
                                    media: '',
                                },
                            ]}
                            altText=""
                        />
                    </div>
                </div>
                {/*11 END*/}
            </SectionIntended>
            {/*12 START*/}
            <div className={MirrorStyles.section12}>
                <ResponsiveImage
                    images={[
                        { path: '_assets/pages/mirror/12/background-small.jpg', media: '(max-width: 767px)' },
                        { path: '_assets/pages/mirror/12/background-large.jpg', media: '(min-width: 768px)' },
                    ]}
                    altText=""
                />

                <div className={MirrorStyles.section12Content}>
                    <Slide direction="right" triggerOnce={false} delay={0}>
                        <HeadlineCopytext
                            alignment="right"
                            color={Color.White}
                            headline={[
                                {
                                    style: 'h3',
                                    text: richText('pages.mirror.sections.12.headlines.0'),
                                },
                                {
                                    style: 'h4',
                                    text: richText('pages.mirror.sections.12.headlines.1'),
                                },
                            ]}
                            copytext={[
                                {
                                    text: richText('pages.mirror.sections.12.copytexts.0'),
                                },
                                {
                                    text: richText('pages.mirror.sections.12.copytexts.1'),
                                },
                            ]}
                        />
                    </Slide>
                </div>
            </div>
            {/*12 END*/}

            <SectionIntended noPadding={true}>
                {/*13 START*/}
                <div className={SharedStyles.mainSlider}>
                    <div className={SharedStyles.mainSliderSlider}>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            autoplay={true}
                            onSlideChange={() => {
                                // do something
                            }}
                            onSwiper={() => {
                                // do something
                            }}
                            pagination={{ clickable: true }}
                            navigation={true}
                        >
                            <SwiperSlide>
                                <div className={SharedStyles.mainSliderSlide}>
                                    <div className={SharedStyles.mainSliderSlideHeader}>
                                        <HeadlineCopytext
                                            color={Color.Purple}
                                            alignment="left"
                                            headline={[
                                                {
                                                    style: 'h3',
                                                    text: 'THE SOCIAL',
                                                },
                                                {
                                                    style: 'h4',
                                                    text: 'SWEATWORK',
                                                },
                                            ]}
                                        />

                                        <h6 className="color-white">Gemeinsames Workout</h6>
                                        <p className="color-white">
                                            Trainiere allein, mit einem Buddy, in der Gruppe oder mit einem Buddy in der
                                            Gruppe.
                                            <sup>
                                                <a href="#footnote-(2)">(2)</a>
                                            </sup>
                                        </p>
                                    </div>
                                    <div className={SharedStyles.mainSliderSlideImage}>
                                        <ResponsiveImage
                                            images={[
                                                {
                                                    path: '_assets/pages/mirror/13/slider-1.png',
                                                    media: '',
                                                },
                                            ]}
                                            altText=""
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={SharedStyles.mainSliderSlide}>
                                    <div className={SharedStyles.mainSliderSlideHeader}>
                                        <HeadlineCopytext
                                            color={Color.Purple}
                                            alignment="left"
                                            headline={[
                                                {
                                                    style: 'h3',
                                                    text: 'THE SOCIAL',
                                                },
                                                {
                                                    style: 'h4',
                                                    text: 'SWEATWORK',
                                                },
                                            ]}
                                        />

                                        <h6 className="color-white">Erfolgs-Tracking</h6>
                                        <p className="color-white">
                                            Tracke, analysiere und perfektioniere deinen Fortschritt.
                                        </p>
                                    </div>
                                    <div className={SharedStyles.mainSliderSlideImage}>
                                        <ResponsiveImage
                                            images={[
                                                {
                                                    path: '_assets/pages/mirror/13/slider-2.png',
                                                    media: '',
                                                },
                                            ]}
                                            altText=""
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={SharedStyles.mainSliderSlide}>
                                    <div className={SharedStyles.mainSliderSlideHeader}>
                                        <HeadlineCopytext
                                            color={Color.Purple}
                                            alignment="left"
                                            headline={[
                                                {
                                                    style: 'h3',
                                                    text: 'THE SOCIAL',
                                                },
                                                {
                                                    style: 'h4',
                                                    text: 'SWEATWORK',
                                                },
                                            ]}
                                        />

                                        <h6 className="color-white">Trophy-System</h6>
                                        <p className="color-white">
                                            Mach Sport zum Game: Erhalte Trophys für deine Fortschritte und wetteifere
                                            mit deinen Buddys.
                                        </p>
                                    </div>
                                    <div className={SharedStyles.mainSliderSlideImage}>
                                        <ResponsiveImage
                                            images={[
                                                {
                                                    path: '_assets/pages/mirror/13/slider-3.png',
                                                    media: '',
                                                },
                                            ]}
                                            altText=""
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={SharedStyles.mainSliderSlide}>
                                    <div className={SharedStyles.mainSliderSlideHeader}>
                                        <HeadlineCopytext
                                            color={Color.Purple}
                                            alignment="left"
                                            headline={[
                                                {
                                                    style: 'h3',
                                                    text: 'THE SOCIAL',
                                                },
                                                {
                                                    style: 'h4',
                                                    text: 'SWEATWORK',
                                                },
                                            ]}
                                        />

                                        <h6 className="color-white">Share-Funktion</h6>
                                        <p className="color-white">
                                            Zeig es allen: Wenn du willst, kannst du deine Workouts mit der Community
                                            teilen.
                                        </p>
                                    </div>
                                    <div className={SharedStyles.mainSliderSlideImage}>
                                        <ResponsiveImage
                                            images={[
                                                {
                                                    path: '_assets/pages/mirror/13/slider-4.png',
                                                    media: '',
                                                },
                                            ]}
                                            altText=""
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                {/*13 END*/}

                {/*14 START*/}
                {/*<TrialMonthMirror />*/}
                {/*14 END*/}

                {/*15 START*/}
                {/*<InView triggerOnce={true}>*/}
                {/*    {({ inView, ref }) => (*/}
                {/*        <div className={SharedStyles.sectionStockist} ref={ref}>*/}
                {/*            {inView && <Stockist type="mirror" />}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</InView>*/}
                {/*15 END*/}

                {/*16 NEWSLETTER START*/}
                {/*<div className={MirrorStyles.sectionNewsletter}>*/}
                {/*    <SectionIntended>*/}
                {/*        <Newsletter*/}
                {/*            headline="NEWSLETTER"*/}
                {/*            buttonTheme={{ type: ButtonThemeType.Gradient, color: ButtonThemeColor.PinkAqua }}*/}
                {/*            theme="page"*/}
                {/*        />*/}
                {/*    </SectionIntended>*/}
                {/*</div>*/}
                {/*16 NEWSLETTER END*/}

                {/*17 START*/}
                <div className={SharedStyles.mainVideo}>
                    <Breakpoint small={true}>
                        <Background
                            height="177%"
                            backgroundColor={Color.Black}
                            imagePath="_assets/pages/mirror/17/background-portrait.jpg"
                            videoPath="_assets/pages/mirror/17/portrait.mp4"
                        />
                    </Breakpoint>

                    <Breakpoint medium={true} large={true} extraLarge={true} ultraLarge={true}>
                        <Background
                            height="56.25%"
                            backgroundColor={Color.Black}
                            imagePath="_assets/pages/mirror/17/background-landscape.jpg"
                            videoPath="_assets/pages/mirror/17/landscape.mp4"
                        />
                    </Breakpoint>

                    <div className={SharedStyles.mainVideoContent}>
                        <Slide direction="up" triggerOnce={false} delay={0}>
                            <HeadlineCopytext
                                color={Color.White}
                                alignment="center"
                                headline={[
                                    {
                                        style: 'h1',
                                        text: 'DAS',
                                    },
                                    {
                                        style: 'h2',
                                        text: 'HORIZON@CYCLE',
                                    },
                                ]}
                                copytext={[
                                    {
                                        text:
                                            'Effektives Allround-Training wie im Fitness-Studio – einfach bei dir zuhause.',
                                    },
                                ]}
                            >
                                <Button
                                    type="button"
                                    to="/cycle"
                                    theme={{
                                        type: ButtonThemeType.SolidWhite,
                                        color: ButtonThemeColor.Transparent,
                                    }}
                                >
                                    JETZT DAS @CYCLE KENNENLEREN
                                </Button>
                            </HeadlineCopytext>
                        </Slide>
                    </div>
                </div>
                {/*17 END*/}
            </SectionIntended>

            {/*FOOTNOTES START*/}
            <SectionIntended>
                <div className={SharedStyles.footnotes}>
                    <div className={SharedStyles.footnotesInner}>
                        <table>
                            <tr>
                                <td>
                                    <p>
                                        <small>(1)</small>
                                    </p>
                                </td>
                                <td>
                                    <div id="footnote-(1)">
                                        <p>
                                            <small>
                                                Beim Kauf eines @Mirrors ist ein Horizon@Abo notwendig, welches
                                                zusätzlich erworben werden muss.
                                            </small>
                                        </p>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>
                                        <small>(2)</small>
                                    </p>
                                </td>
                                <td>
                                    <div id="footnote-(2)">
                                        <p>
                                            <small>
                                                Der Kauf eines @Mirrors ist mit einer unverbindlichen 30-tägigen
                                                Testphase verbunden. Du hast Anspruch auf eine 30-tägige Testphase pro
                                                Haushalt/Lieferadresse. Das Angebot ist nicht übertragbar und gilt nur
                                                für Kunden in Deutschland und Österreich innerhalb des Liefergebiets der
                                                Johnson Health Tech. GmbH. Das Angebot über eine 30-tägige Testphase
                                                gilt nur für Verbraucher, die online auf www.shop-horizonfitness.eu ein
                                                @Cycle oder einen @Mirror erworben haben und ist nicht für Unternehmen
                                                verfügbar. Das 30-tägige Probetraining beginnt mit der Aktivierung des
                                                @Mirror mit dem bereitgestellten Aktivierungscode.
                                            </small>
                                        </p>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>
                                        <small>(3)</small>
                                    </p>
                                </td>
                                <td>
                                    <div id="footnote-(3)">
                                        <p>
                                            <small>
                                                Sofern die Lieferart „Lieferung zum Aufstellort“ gewählt wurde, werden
                                                die Versandkosten nicht zurückerstattet. Des Weiteren fallen im Zuge
                                                einer Retoure, durch Abholung unseres Spediteurs, Kosten i.H.v. 95 Euro
                                                an. Eine explizite Kündigung des Horizon@Abos ist im Falle einer
                                                Rückgabe des Gerätes innerhalb der 30-tägigen Testphase nicht
                                                erforderlich. Diese endet automatisch, sofern der @Mirror online auf
                                                www.shop-horizonfitness.eu erworben wurde.
                                            </small>
                                        </p>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>
                                        <small>(4)</small>
                                    </p>
                                </td>
                                <td>
                                    <div id="footnote-(4)">
                                        <p>
                                            <small>
                                                Die Nutzung des 1:1 Trainings ist für einen Familienaccount auf 6
                                                Stunden pro Woche limitiert. Nach Ablauf des freien Kontingents wird das
                                                1:1 Training automatisch beendet. Bei Nutzung des 1:1 Trainings kann
                                                ausschließlich über ein separat erhältliches Bluetooth Headset
                                                miteinander kommuniziert werden.
                                            </small>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </SectionIntended>
            {/*FOOTNOTES END*/}
        </PageLayout>
    );
};

export default MirrorPage;
